require("./polyfills")();

// Fixme: gettext
require("./gettext")();

require("angular");
require("textangular");
require("angular-sanitize");
require("angular-animate");
require("angular-touch");
require("angular-ui-bootstrap");
require("angular-cookies");
require("ui-select");
require("angular-route");
require("bootstrap-ui-datetime-picker");

var moduleRequirements = ["ngCookies", "ui.bootstrap", "ngAnimate", "ngTouch",
        "ui.select", "ngRoute", "ngSanitize", "textAngular", "ui.bootstrap.datetimepicker"],
    app = angular.module("intake24.admin", moduleRequirements);

require("./core")(app);
require("./explorer")(app);
require("./image-gallery")(app);
require("./user-managers")(app);
require("./survey-manager")(app);
require("./survey-feedback")(app);
require("./database-tools")(app);
require("./welcome/welcome.controller")(app);
require("./set-routes")(app);
require("./textangular-config")(app);
require("./bootstrap-ui-datetime-picker-config")(app);

window.hideFoodDatabase = false;
window.api_base_url = "https://api.intake24.com/";
window.recaptcha = {"enabled":false,"site_key":"6LdOepoUAAAAAKSVe4-Q9BeW7dPJAQD5yHh_qRq-"};